import React, { useState } from "react"
import { Link } from "gatsby"
import { IoIosArrowForward } from "react-icons/io"
import { FaTimes } from "react-icons/fa"

const CourseSidebar = ({ content, handleClick }) => {
  const [sidebar, setSidebar] = useState(false)

  const showSidebar = () => setSidebar(!sidebar)
  const onTrigger = () => {
    setSidebar(!sidebar)
    handleClick()
  }

  return (
    <div>
      <div className="mb-only">
        <div className="course-sidebar-nav-mb">
          <p>Table of contents</p>
          <IoIosArrowForward onClick={showSidebar} />
        </div>
        {sidebar && (
          <div className="course-sidebar-mb">
            <div style={{ width: "100%" }}>
              <div className="course-sidebar-mb-header">
                <h4 style={{ color: "white" }}>Vietnamese Guide</h4>
                <button className="close-btn" onClick={showSidebar}>
                  <FaTimes />
                </button>
              </div>
              <div className="course-sidebar-article">
                {content.items.map(i => (
                  <Link to={i.url} key={i.url}>
                    <button
                      key={i.url}
                      className="course-sidebar-mb-link"
                      onClick={onTrigger}
                    >
                      {i.title}
                    </button>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="course-sidebar">
        <h4 className="course-sidebar-title">Table of content</h4>
        <div className="course-sidebar-article">
          {content.items.map(i => (
            <Link to={i.url} key={i.url}>
              <button
                key={i.url}
                className="course-sidebar-link"
                onClick={() => handleClick()}
              >
                {i.title}
              </button>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CourseSidebar
