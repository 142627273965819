import React, { useState, useEffect } from "react"

const Quiz = ({ quiz }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [showScore, setShowScore] = useState(false)
  const [score, setScore] = useState(0)
  const [questions, setQuestion] = useState(quiz)
  const [restart, setRestart] = useState(false)

  useEffect(() => {
    const suffledQuestions = questions
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
    setQuestion(suffledQuestions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restart])

  const handleAnswerOptionClick = isCorrect => {
    if (isCorrect) {
      setScore(score + 1)
    }

    const nextQuestion = currentQuestion + 1
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion)
    } else {
      setShowScore(true)
    }
  }

  const clearState = () => {
    setShowScore(false)
    setCurrentQuestion(0)
    setScore(0)
    setRestart(true)
  }

  return (
    <div className="quiz-box">
      {showScore ? (
        <div>
          {score >= questions.length - 1 && <h3>You did great!</h3>}
          {score <= questions.length / 2 && <h3>You can do better!</h3>}
          <p>
            You scored {score} out of {questions.length}
          </p>
          <button className="btn" onClick={() => clearState()}>
            Restart
          </button>
        </div>
      ) : (
        <div>
          <div className="question-section">
            <h4 className="question-count">
              <span>Question {currentQuestion + 1}</span>/{questions.length}
            </h4>
            <div className="hide"></div>
            <h5 className="question-text">
              {questions[currentQuestion].questionText}
            </h5>
          </div>
          <div className="answer-section">
            {questions[currentQuestion].answerOptions.map(
              (answerOption, index) => (
                <button
                  className="button"
                  key={index}
                  onClick={() =>
                    handleAnswerOptionClick(answerOption.isCorrect)
                  }
                >
                  {answerOption.answerText}
                </button>
              )
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Quiz
