import React from "react"

/*
 * TODO:
 * - [ ] Puts children into boxes, uses outside wrapper to alignment
 * - [ ] Depending on person (A B C) - sets color
 */

const Sentence = ({ pre, children, trans }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-star",
        alignItems: "center",
      }}
    >
      {pre && <div style={{ marginRight: "var(--s0)" }}>{pre}:</div>}
      <div style={{ marginRight: "var(--s0)" }}>{children}</div>
      {trans && (
        <div style={{ color: "var(--clr-greyish)", fontStyle: "italic" }}>
          {trans}
        </div>
      )}
    </div>
  )
}

export default Sentence
