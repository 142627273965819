import React from "react"

const Alert = ({ type, children }) => {
  return (
    <fieldset className={`alert ${type}`}>
      <legend className={`alert-title ${type}`}>{type}</legend>
      <p>{children}</p>
    </fieldset>
  )
}

export default Alert
