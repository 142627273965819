import React from "react"

const FlashCard = ({ eng, vn }) => {
  return (
    <div className="flash-card">
      <div className="flash-card-inner">
        <div className="flash-card-front">
          <p>{eng}</p>
        </div>
        <div className="flash-card-back">
          <p>{vn}</p>
        </div>
      </div>
    </div>
  )
}

export default FlashCard
