import React from "react"

/*
 * TODO:
 * = [ ] Button to show/hide English translation
 */

const Example = ({ children }) => {
  return (
    <fieldset
      style={{
        border: "2px solid var(--clr-greyish)",
        margin: "var(--s2) 0",
        padding: "var(--s1)",
      }}
    >
      <legend
        style={{
          color: "var(--clr-grey)",
        }}
      >
        EXAMPLE
      </legend>
      <div>{children}</div>
    </fieldset>
  )
}

export default Example
