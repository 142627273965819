import React from "react"

/*
 * TODO:
 * = [ ] Button to show/hide English translation
 */

const Conversation = ({ children }) => {
  return (
    <fieldset
      style={{
        border: "2px solid var(--clr-red)",
        margin: "var(--s2) 0",
        padding: "var(--s1)",
      }}
    >
      <legend
        style={{
          color: "var(--clr-red)",
          marginLeft: "auto",
        }}
      >
        CONVERSATION
      </legend>
      {children}
    </fieldset>
  )
}

export default Conversation
