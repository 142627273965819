import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import CourseSidebar from "../components/CourseSidebar"
import Layout from "../components/layout/Layout"
import Seo from "../components/SEO"

import Alert from "../components/Alert"
import Sentence from "../components/Sentence"
import Conversation from "../components/Conversation"
import Example from "../components/Example"
import Quiz from "../components/Quiz"
import Slider from "../components/Slider"

const CourseTemplate = ({ data, location }) => {
  const { frontmatter, body, tableOfContents, parent, headings } = data.mdx
  const { description, title, date } = frontmatter

  const [prev, setPrev] = useState()
  const [next, setNext] = useState()

  const shortcodes = {
    Alert,
    Conversation,
    Example,
    Sentence,
    Quiz,
    Slider,
  }

  // Convert all headings to slug
  let newItems = []

  let slugify = require("slugify")

  headings.map(heading => {
    return newItems.push(
      slugify(heading.value.toLowerCase(), { remove: /[*+~.()'"!:@]/g })
    )
  })

  // Add className hide to all elements
  useEffect(() => {
    const list = document.querySelector("#main").children
    for (let i = 0; i < list.length; ++i) {
      list[i].classList.add("hide")
    }
  }, [])

  // Add active class to section when slug is changed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (location.hash) {
      let uri = document.querySelector(location.hash)
      if (uri !== null) {
        uri.classList.add("active")
        uri.nextSibling.classList.add("active")
        // Check if there's previous lesson.
        if (uri.previousSibling) {
          const prevUri = uri.previousSibling.previousSibling.id
          setPrev(prevUri)
        } else {
          setPrev()
        }
        // Check if there's next lesson.
        if (uri.nextSibling.nextSibling) {
          const nextUri = uri.nextSibling.nextSibling.id
          setNext(nextUri)
        } else {
          setNext()
        }
      }
    }
  })

  // Remove active class when the section is not click. Also, scroll to top when button clicked.
  const handleClick = () => {
    let elems = document.querySelectorAll(".active")
    ;[].forEach.call(elems, function (el) {
      el.classList.remove("active")
    })
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" })
    })
  }

  return (
    <div onLoad={() => (window.location.href = `#${newItems[0]}`)}>
      <Layout>
        <Seo
          title={title}
          desc={description}
          pathname={location.pathname}
          article
          datePub={date}
          dateMod={parent.modifiedTime}
        />
        <div className="course-container">
          <CourseSidebar content={tableOfContents} handleClick={handleClick} />
          <div className="main-course">
            <article id="main" className="content">
              <MDXProvider components={shortcodes}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>
            </article>
            <div id="navigation-buttons">
              {prev && (
                <a href={`#${prev}`}>
                  <button className="prev-button" onClick={handleClick}>
                    &laquo; Previous
                  </button>
                </a>
              )}
              {next && next !== "navigation-buttons" && (
                <a href={`#${next}`}>
                  <button className="next-button" onClick={handleClick}>
                    Next &raquo;
                  </button>
                </a>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query getCourse($slug: String) {
    mdx(slug: { eq: $slug }) {
      id
      slug
      body
      tableOfContents
      frontmatter {
        author
        description
        title
        date(formatString: "MMMM Do, YYYY")
      }
      headings(depth: h2) {
        value
      }
      parent {
        ... on File {
          modifiedTime(formatString: "MMMM Do, YYYY")
        }
      }
    }
  }
`

export default CourseTemplate
