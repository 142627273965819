import React, { useState } from "react"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import FlashCard from "./FlashCard"

const Slider = ({ cards }) => {
  const [index, setIndex] = useState(0)

  const { eng, vn } = cards[index]

  const checkNumber = number => {
    if (number > cards.length - 1) {
      return 0
    }
    if (number < 0) {
      return cards.length - 1
    }
    return number
  }
  const nextItem = () => {
    setIndex(index => {
      let newIndex = index + 1
      return checkNumber(newIndex)
    })
  }
  const prevItem = () => {
    setIndex(index => {
      let newIndex = index - 1
      return checkNumber(newIndex)
    })
  }

  return (
    <section className="slider-container">
      <div className="slider">
        <FlashCard eng={eng} vn={vn} />
        <div className="button-container">
          <button className="prev-btn" onClick={prevItem}>
            <FaChevronLeft />
          </button>
          <button className="next-btn" onClick={nextItem}>
            <FaChevronRight />
          </button>
        </div>
      </div>
    </section>
  )
}

export default Slider
